import ChartBarIcon from "@heroicons/react/24/solid/ChartBarIcon";
import ShoppingBagIcon from "@heroicons/react/24/solid/ShoppingBagIcon";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import {SvgIcon} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import BusinessIcon from "@mui/icons-material/Business";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import GiftIcon from "@mui/icons-material/CardGiftcard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";
import InventoryIcon from "@mui/icons-material/Inventory";
import SettingsIcon from "@mui/icons-material/Settings";
import SchoolIcon from "@mui/icons-material/School";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";

export const items = [
    {
        title: "Overview",
        path: "/",
        icon: (
            <SvgIcon fontSize="small">
                <ChartBarIcon/>
            </SvgIcon>
        ),
        roles: ["SUPER_ADMIN", "ADMIN", "SUBORDINATE"],
    },

    {
        title: "Suppliers",
        path: "/suppliers",
        icon: (
            <SvgIcon fontSize="small">
                <UsersIcon/>
            </SvgIcon>
        ),
        roles: ["SUPER_ADMIN", "ADMIN"],
    },
    {
        title: "LPO Orders",
        path: "/purchase-orders",
        icon: (
            <SvgIcon fontSize="small">
                <ShoppingCartIcon/>
            </SvgIcon>
        ),
        roles: ["SUPER_ADMIN", "ADMIN", "SUBORDINATE"],
    },
    {
        title: "Inventory",
        path: "/inventory",
        icon: (
            <SvgIcon fontSize="small">
                <InventoryIcon/>
            </SvgIcon>
        ),
        roles: ["SUPER_ADMIN", "ADMIN", "SUBORDINATE"],
    },
    {
        title: "Service Providers",
        path: "/service-providers",
        icon: (
            <SvgIcon fontSize="small">
                <CoPresentIcon/>
            </SvgIcon>
        ),
        roles: ["SUPER_ADMIN", "ADMIN"],
    },
    {
        title: "LSO Services",
        path: "/lso",
        icon: (
            <SvgIcon fontSize="small">
                <CreditCardIcon/>
            </SvgIcon>
        ),
        roles: ["SUPER_ADMIN", "ADMIN", "SUBORDINATE"],
    },
    {
        title: "LSO Orders",
        path: "/lso-orders",
        icon: (
            <SvgIcon fontSize="small">
                <BusinessIcon/>
            </SvgIcon>
        ),
        roles: ["SUPER_ADMIN", "ADMIN", "SUBORDINATE"],
    },
    {
        title: "Parent Suppliers",
        path: "/suppliers-parents",
        icon: (
            <SvgIcon fontSize="small">
                <FamilyRestroomIcon/>
            </SvgIcon>
        ),
        roles: ["SUPER_ADMIN", "ADMIN"],
    },

    {
        title: "Parent Orders",
        path: "/parents-orders",
        icon: (
            <SvgIcon fontSize="small">
                <SupervisedUserCircleIcon/>
            </SvgIcon>
        ),
        roles: ["SUPER_ADMIN", "ADMIN", "SUBORDINATE"],
    },
    {
        title: "Deliveries",
        path: "/deliveries",
        icon: (
            <SvgIcon fontSize="small">
                <ShoppingBagIcon/>
            </SvgIcon>
        ),
        roles: ["SUPER_ADMIN", "ADMIN", "SUBORDINATE"],
    },
    {
        title: "Supplier Payments",
        path: "/payments",
        icon: (
            <SvgIcon fontSize="small">
                <WalletIcon/>
            </SvgIcon>
        ),
        roles: ["SUPER_ADMIN", "ADMIN"],
    },
    {
        title: "Staff Issuance",
        path: "/issuance",
        icon: (
            <SvgIcon fontSize="small">
                <GiftIcon/>
            </SvgIcon>
        ),
        roles: ["SUPER_ADMIN", "ADMIN", "SUBORDINATE"],
    },
    {
        title: "Staff Registration",
        path: "/staff",
        icon: (
            <SvgIcon fontSize="small">
                <PeopleIcon/>
            </SvgIcon>
        ),
        roles: ["SUPER_ADMIN", "ADMIN", "SUBORDINATE"],
    },
    {
        title: "Students",
        path: "/student-allocations",
        icon: (
            <SvgIcon fontSize="small">
                <SchoolIcon/>
            </SvgIcon>
        ),
        roles: ["SUPER_ADMIN", "ADMIN", "SUBORDINATE"],
    }, // General
    {
        title: "Settings",
        path: "/settings",
        icon: (
            <SvgIcon fontSize="small">
                <SettingsIcon/>
            </SvgIcon>
        ),
        roles: ["SUPER_ADMIN", "ADMIN", "SUBORDINATE"],
    },
];
