import { useCallback, useEffect, useRef, useState } from "react";
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { AllocationTable } from "../../../sections/allocations/allocations-table";
import { makeNetworkCall } from "../../../utils/fetchData";
import { useParams } from "react-router-dom";
import { IssuedItemTabTable } from "../../../sections/studentAllocations/issuedItems-tab/issuedItems-table";
import { DeliveriesTable } from "../../../sections/deliveries/deliveries-table";
import { PaymentTable } from "../../../sections/payments/payments-table";
import { OrdersTable } from "../../../sections/purchaseOrders/purchaseOrders-table";
import {
  DEFAULT_PER_PAGE,
  PRODUCT_DELIVERIES_ENDPOINT,
  PRODUCT_ORDERS_ENDPOINT,
  PRODUCT_PAYMENTS_ENDPOINT,
  PRODUCT_STAFF_ISSUANCE_ENDPOINT,
  PRODUCT_STUDENT_ISSUANCE_ENDPOINT,
} from "../../../utils/constants";

export const useProducts = (tab, page, rowsPerPage, id) => {
  const [isLoading, setIsLoading] = useState(false);
  const [productPayments, setProductPayments] = useState([]);
  const [productOrders, setProductOrders] = useState([]);
  const [ProductDeliveries, setProductDeliveries] = useState([]);
  const [productAllocations, setProductAllocation] = useState([]);
  const [productStudents, setProductStudents] = useState([]);
  const [deliveryTotals, setDeliveryTotals] = useState({});
  const [staffIssuanceTotals, setStaffIssuanceTotals] = useState({});
  const [studentIssuanceTotals, setStudentIssuanceTotals] = useState({});
  const [paymentsTotals, setPaymentsTotals] = useState({});
  const [ordersTotals, setOrderTotals] = useState({});

  const fetchProducts = async (endpoint, setter, totalCountSetter) => {
    await makeNetworkCall(
      endpoint,
      {
        offset: page * rowsPerPage,
        per_page: rowsPerPage,
        product_id: id,
      },
      setter,
      totalCountSetter,
      setIsLoading,
      {},
      "POST"
    );
  };
  useEffect(() => {
    const fetch = async () => {
      switch (tab) {
        case 0:
          await fetchProducts(
            PRODUCT_DELIVERIES_ENDPOINT,
            setProductDeliveries,
            setDeliveryTotals
          );
          break;
        case 1:
          await fetchProducts(
            PRODUCT_STAFF_ISSUANCE_ENDPOINT,
            setProductAllocation,
            setStaffIssuanceTotals
          );
          break;
        case 2:
          await fetchProducts(
            PRODUCT_PAYMENTS_ENDPOINT,
            setProductPayments,
            setPaymentsTotals
          );
          break;
        case 3:
          await fetchProducts(
            PRODUCT_ORDERS_ENDPOINT,
            setProductOrders,
            setOrderTotals
          );
          break;
        case 4:
          await fetchProducts(
            PRODUCT_STUDENT_ISSUANCE_ENDPOINT,
            setProductStudents,
            setStudentIssuanceTotals
          );
          break;
        default:
          break;
      }
    };
    fetch();
  }, [tab, page, rowsPerPage]);

  return {
    isLoading,
    productPayments,
    productOrders,
    ProductDeliveries,
    productAllocations,
    productStudents,
    deliveryTotals,
    staffIssuanceTotals,
    paymentsTotals,
    ordersTotals,
    studentIssuanceTotals,
  };
};

const ProductPage = () => {
  const { id, tittle } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE);
  const [activeTab, setActiveTab] = useState(0);
  const {
    isLoading,
    deliveryTotals,
    staffIssuanceTotals,
    paymentsTotals,
    ordersTotals,
    studentIssuanceTotals,
    productPayments: productPaymentsData,
    productOrders: productOrdersData,
    ProductDeliveries: ProductDeliveriesData,
    productAllocations: productAllocationsData,
    productStudents: productStudentsData,
  } = useProducts(activeTab, page, rowsPerPage, id);
  const componentRef = useRef();

  const handlePageChange = useCallback((event, value) => {
    setPage(value);
  }, []);

  const handleRowsPerPageChange = useCallback((event) => {
    setRowsPerPage(event.target.value);
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Helmet>
        <title>Procurement | School Inventory </title>
      </Helmet>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
        }}
      >
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Stack spacing={1}>
              <Typography variant="h4">{tittle}</Typography>
            </Stack>
          </Stack>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
          >
            <Tab label="Inventory Deliveries" />
            <Tab label="Staff Issuance" />
            <Tab label="Inventory Payments" />
            <Tab label="Inventory Orders" />
            <Tab label="Student Issuance" />
          </Tabs>
          <Box hidden={activeTab !== 0}>
            <DeliveriesTable
              totals={deliveryTotals}
              items={ProductDeliveriesData}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              isLoading={isLoading}
              componentRef={componentRef}
            />
          </Box>
          <Box hidden={activeTab !== 1}>
            <AllocationTable
              count={staffIssuanceTotals.total}
              items={productAllocationsData}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              isLoading={isLoading}
              componentRef={componentRef}
            />
          </Box>
          <Box hidden={activeTab !== 2}>
            <PaymentTable
              totals={paymentsTotals}
              items={productPaymentsData}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              isLoading={isLoading}
              componentRef={componentRef}
            />
          </Box>
          <Box hidden={activeTab !== 3}>
            <OrdersTable
              type="lpo"
              totals={ordersTotals}
              items={productOrdersData}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              isLoading={isLoading}
              componentRef={componentRef}
            />
          </Box>
          <Box hidden={activeTab !== 4}>
            <IssuedItemTabTable
              count={studentIssuanceTotals.total}
              items={productStudentsData}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              isLoading={isLoading}
              componentRef={componentRef}
            />
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default ProductPage;
