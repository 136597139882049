import PropTypes from "prop-types";
import {Avatar, Card, CardContent, Stack, SvgIcon, Typography} from "@mui/material";
import {DeliveryDining} from "@mui/icons-material";

export const OverviewTodayDeliveries = (props) => {
    const {sx, value} = props;

    return (
        <Card sx={sx}>
            <CardContent>
                <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                    <Stack spacing={1}>
                        <Typography color="text.secondary" variant="overline">
                            Today Deliveries
                        </Typography>
                        <Typography variant="h5">{value}</Typography>
                    </Stack>
                    <Avatar
                        sx={{
                            backgroundColor: "success.main",
                            height: 56,
                            width: 56,
                        }}
                    >
                        <SvgIcon>
                            <DeliveryDining/>
                        </SvgIcon>
                    </Avatar>
                </Stack>
            </CardContent>
        </Card>
    );
};

OverviewTodayDeliveries.propTypes = {
    difference: PropTypes.number,
    positive: PropTypes.bool,
    value: PropTypes.string.isRequired,
    sx: PropTypes.object,
};
