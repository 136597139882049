import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { SCHOOL_ORDERS_ENDPOINT } from "../../utils/constants";
import BetweenDatePicker from "../../utils/betweenDates-picker";
import { BaseOrdersSearchBox } from "../orders-search-box";

export const PurchaseOrdersSearch = ({
  data,
  setData,
  setIsLoading,
  setTotals,
  payload,
}) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (startDate && endDate) {
      payload.start_date = startDate;
      payload.end_date = endDate;
    }
  }, [endDate, startDate]);

  const dateFilters = (
    <Box sx={{ width: "100%", display: "flex", marginLeft: "25px" }}>
      <BetweenDatePicker
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        label="Filters between dates"
      />
    </Box>
  );

  return BaseOrdersSearchBox({
    placeholder: "LPO/LSO no. or inventory name...",
    endpoint: SCHOOL_ORDERS_ENDPOINT,
    data: data,
    setData: setData,
    setTotals: setTotals,
    setIsLoading: setIsLoading,
    payload: payload,
    otherFilters: dateFilters,
  });
};
