import axios from "axios";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
    GET_ENDPOINT,
    GET_SETTINGS_ENDPOINT,
    POST_FORM_ENDPOINT,
    POST_RAW_ENDPOINT,
    SCHOOL_SETTINGS_ENDPOINT,
    SYSTEM_SETTINGS_ENDPOINT,
} from "./constants";

export const makeNetworkCall = async (
    url,
    form_data,
    setData,
    setTotal,
    setIsLoading,
    payload,
    httpMethod = "POST",
    setInputData = null,
    inputData = null
) => {
    try {
        if (setIsLoading != null) setIsLoading(true);
        const storedToken = localStorage.getItem("token");
        if (!storedToken) {
            console.error("Token not found in localStorage");
            return;
        }

        let fetchedData;
        if (httpMethod === "POST") {
            const params = new FormData();
            for (const param in form_data) {
                params.append(param, form_data[param]);
            }
            fetchedData = await axios.post(POST_FORM_ENDPOINT, params, {
                headers: {
                    Token: storedToken,
                    Url: url,
                },
            });
        } else if (httpMethod === "RAW") {
            fetchedData = await axios.post(POST_RAW_ENDPOINT, payload, {
                headers: {
                    Token: storedToken,
                    Url: url,
                },
            });
        } else {
            fetchedData = await axios.get(GET_ENDPOINT, {
                headers: {
                    Token: storedToken,
                    Url: url,
                },
            });
        }
        if (setData != null) setData(fetchedData?.data?.data);
        if (setTotal != null) {
            setTotal(fetchedData?.data?.message);
        }
        if (setInputData != null) {
            setInputData({...inputData, stream: fetchedData?.data?.data[0]});
        }
        if (setIsLoading != null) setIsLoading(false);
        if (httpMethod === "RAW") {
            if (fetchedData.data.success === true) {
                toast.success(fetchedData.data.message || "Item Created successfully ");
                await delay(2000);
                window.location.reload();
            } else {
                toast.error(fetchedData.data.message || "Error during item creation");
            }
        }
        return fetchedData.data;
    } catch (error) {
        if (setIsLoading != null) setIsLoading(false);
        console.error("Error fetching data:", error);
        return [];
    }
};
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const fetchSettingsData = async (setData) => {
    makeNetworkCall(SYSTEM_SETTINGS_ENDPOINT, {}, setData, null, null, {}, "GET");
};

export const fetchDepartmentSettingsData = async (setData) => {
    await makeNetworkCall(
        SCHOOL_SETTINGS_ENDPOINT,
        {},
        setData,
        null,
        null,
        {},
        "GET"
    );
};

export const fetchSettings = async (setData) => {
    await makeNetworkCall(
        GET_SETTINGS_ENDPOINT,
        {},
        setData,
        null,
        null,
        {},
        "GET"
    );
};

export const CreateItem = async (postData, url) => {
    await makeNetworkCall(url, {}, null, null, null, postData, "RAW");
};
