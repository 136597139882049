import {formatDisplayNumber} from "../utils/number-formter";

export const printLpoReceipt = (formatPrintData, type) => {
    const printWindow = window.open(
        "",
        "_blank",
        "toolbar=0,location=0,menubar=0,rel=noreferrer"
    );

    if (!printWindow) {
        alert("Please allow pop-ups for this website");
        return;
    }
    console.log(formatPrintData);

    const storedToken = localStorage.getItem("userData");
    const options = {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};
    const userData = storedToken ? JSON.parse(storedToken) : null;
    const date = new Date();
    const formattedDate = date.toLocaleDateString("en-US", options);
    const formattedTime = date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });

    const dateTime = `${formattedDate}, ${formattedTime}`;

    const schoolPrefix = userData?.school?.school_prefix;
    const schoolName = userData?.school?.school_name;
    const schoolAddress = userData?.school?.postal_address;
    const schoolPhone = userData?.school?.phone_number;
    const schoolAdmin = userData?.user?.full_name;

    try {
        printWindow.document.open();

        const dataLength = formatPrintData.data.length;
        let fontSizeBody,
            fontSizeHeading,
            paddingCell,
            paddingTable,
            marginTopSignature,
            marginBottomInfo,
            marginRightInfo;

        if (dataLength <= 9) {
            fontSizeBody = "16px";
            fontSizeHeading = "18px";
            paddingCell = "10px";
            paddingTable = "20px";
            marginTopSignature = "20px";
            marginBottomInfo = "20px";
            marginRightInfo = "10px";
        } else if (dataLength <= 20) {
            fontSizeBody = "12px";
            fontSizeHeading = "16px";
            paddingCell = "8px";
            paddingTable = "15px";
            marginTopSignature = "15px";
            marginBottomInfo = "15px";
            marginRightInfo = "5px";
        } else if (dataLength <= 30) {
            fontSizeBody = "10px";
            fontSizeHeading = "14px";
            paddingCell = "5px";
            paddingTable = "10px";
            marginTopSignature = "10px";
            marginBottomInfo = "10px";
            marginRightInfo = "2px";
        } else {
            fontSizeBody = "8px";
            fontSizeHeading = "12px";
            paddingCell = "4px";
            paddingTable = "8px";
            marginTopSignature = "8px";
            marginBottomInfo = "8px";
            marginRightInfo = "0px";
        }

        printWindow.document.write(`
      <html>
        <head>
          <title>Document Report</title>
          <style>
            @page {
              size: 9.5in 11in; 
              margin: 0; 
            }
            @media print {
              body, html {
                width: 24.13cm; 
                height: 27.94cm; 
              }
                header, footer {
                display: none
                }
            }
            body {
              margin: 0;
              padding: 0;
              font-family: 'Courier New', Courier, monospace;
              font-weight: bold;
            }
            table, th, td {
              font-weight: bold;
            }
          </style>
        </head>
        <body>
        <div
          style="
            height: 27.94cm; 
            width: 24.13cm;  
            padding-left: 1cm;
            padding-right: 1cm;
            background-color: black;
            position: relative;
          "
        >
        <!-- bottom header -->
          <div
            style="
              height: 11.2cm;
              display: flex;
              position: relative;
              background-color: aqua;
            "
          >
            <!-- Corrected to 3.8cm from the top -->
            <h1
              style="
                position: absolute;
                top: 1cm;
                left: 45%;
                transform: translateX(-50%);
              "
            >
              ${type}
            </h1>

            <h1 style="position: absolute; top: 1.3cm; right: 1.5cm">${
            formatPrintData.order_number
        }</h1>

            <!-- The rest of your elements -->
            <h3 style="position: absolute; top: 5.3cm; left: 0.5cm">
              ${formatPrintData.company_name}
            </h3>
            <h3 style="position: absolute; top: 5.3cm; right: 6cm"></h3>
            <h3 style="position: absolute; top: 6.5cm; left: 2.5cm">${
            formatPrintData.company_address
        }</h3>
            <h3 style="position: absolute; top: 6.5cm; right: 6cm">
             ${new Date(formatPrintData.createdDate).toLocaleDateString('en-US', options)}
             </h3>
            <h3 style="position: absolute; top: 8.5cm; right: 5cm">
              ${schoolName}
            </h3>
            <h3 style="position: absolute; top: 9.2cm; left: 3cm"></h3>
          </div>


          <table style="height: 11cm; width: 100%; position: relative; margin-left: -1cm;">
            <thead style="height: 2.3cm">
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody style="height: 12cm; width: 100%;  position: relative;">
              ${formatPrintData.data
            .map(
                (order, index) =>
                    `<tr style="width: 100%;">
                      <td style="width: 1cm; height: 0.7px;">${index + 1}</td>
                      <td style="width: 1cm; height: 0.7px;">${
                        type === "LOCAL PURCHASE ORDER" ? order.quantity : ""
                    }</td>
                      <td style="width: 9cm; height: 0.7px;">${
                        order.item_name
                    }</td>
                      <td style="width: 1.9cm; height: 0.7px;">${
                        type === "LOCAL PURCHASE ORDER"
                            ? order.unitOfMeasurement
                            : ""
                    }</td>
                      <td style="width: 2.6cm; height: 0.7px;">${
                        type === "LOCAL PURCHASE ORDER"
                            ? (order.itemPrice / order.quantity).toFixed(2)
                            : ""
                    }</td>
                      <td style="width: 4.4cm; height: 0.7px;">${formatDisplayNumber(
                        order.itemPrice
                    )}.00</td>
                    </tr>`
            )
            .join("")}
              <tr style="position: relative;">
                <td colspan="5"></td>
                <td style="position: absolute; bottom: -2.2px;">Ksh. ${formatDisplayNumber(
            formatPrintData.totalPrice
        )}.00</td>
              </tr>
            </tbody>
          </table>

          <div
            style="
              height: 3.7cm;
              position: relative;
              background: red;
              width: 100%;
            "
          >
            <h3 style="position: absolute; bottom: -0.8cm; left: 3cm">${dateTime}</h3>
          </div>
        </div>
        </body>
      </html>
    `);

        printWindow.document.close();
        printWindow.onafterprint = () => {
            printWindow.close();
        };

        printWindow.onload = function () {
            printWindow.print();
        };
    } catch (error) {
        console.error(error);
    }
};
