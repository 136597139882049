import { useMemo } from "react";
import { useSelection } from "../../hooks/use-selection";
import { ProductSearch } from "../../sections/products/product-search";
import { ProductTable } from "../../sections/products/product-table";
import AddProduct from "../../sections/products/add-product";
import * as actions from "../../layouts/dashboard/actions";
import { PRODUCTS_ENDPOINT } from "../../utils/constants";
import BasePageConfigs from "../page-configs";
import BasePage from "../page-blueprint";
import { useLoadData } from "../../services/featching-data-service";

const useProductIds = (products) => {
  return useMemo(() => {
    if (!products) {
      return [];
    }
    return products.map((product) => product.id);
  }, [products]);
};

const ProductsPage = () => {
  const {
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    componentRef,
  } = BasePageConfigs();

  const payload = {
    offset: page * rowsPerPage,
    per_page: rowsPerPage,
    product_type: "inventory",
  };

  const {
    data: products,
    total,
    isLoading,
    setTotals,
    setData,
    setIsLoading,
  } = useLoadData(payload, PRODUCTS_ENDPOINT);
  const productIds = useProductIds(products);
  const productsSelection = useSelection(productIds);

  return BasePage(
    "School Inventory",
    <AddProduct />,
    <ProductSearch
      data={products}
      payload={payload}
      setTotals={setTotals}
      setIsLoading={setIsLoading}
      setData={setData}
    />,
    <ProductTable
      count={total}
      items={products}
      onDeselectAll={productsSelection.handleDeselectAll}
      onDeselectOne={productsSelection.handleDeselectOne}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      onSelectAll={productsSelection.handleSelectAll}
      onSelectOne={productsSelection.handleSelectOne}
      page={page}
      rowsPerPage={rowsPerPage}
      selected={productsSelection.selected}
      isLoading={isLoading}
      componentRef={componentRef}
    />,
    componentRef,
    actions.canAddInventory
  );
};

export default ProductsPage;
