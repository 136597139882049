export const StaffSortFilters = [
    {key: "id", label: "Date Added"},
    {key: "full_name", label: "Staff Name"},
];


export const SuppliersSortFilters = [
    {key: "id", label: "Date Added"},
    {key: "balance_owed", label: "Unpaid Balance"},
    {key: "total_paid", label: "Total Paid"},
    {key: "invoiced_amount", label: "Invoiced Amount"},
];

export const LsoOrdersSortFilters = [
    {key: "id", label: "Date Added"},
    {key: "order_amount", label: "Order Amount"},
    {key: "balance", label: "Balance"},
    {key: "invoiced_amount", label: "Invoiced Amount"},
];

export const InventoriesSortFilters = [
    {key: "id", label: "Date Added"},
    {key: "total_in_stock", label: "Stock Quantity"},
    {key: "active_orders", label: "Active Orders"},
    {key: "total_quantity_delivered", label: "Total Delivered"},
];

export const PaymentSortFilters = [
    {key: "id", label: "Date"},
    {key: "amount", label: "Payment Amount"},
    {key: "balance", label: "Balance"},
    {key: "invoiced_amount", label: "Invoiced Amount"},
];

export const ServiceSortFilters = [
    {key: "balance", label: "Balance"},
    {key: "invoiced_amount", label: "Invoiced Amount"},
];

export const LsoSortFilters = [
    {key: "id", label: "Date Added"},
    {key: "active_orders", label: "Active Orders"},
];

export const DeliveriesSortFilters = [
    {key: "id", label: "Date Added"},
    {key: "quantity", label: "QTY Delivered"},
    {key: "invoice_amount", label: "Invoiced Amount"},
    {key: "in_stock", label: "In Stock"},
];

export const AllocationSortFilters = [
    {key: "id", label: "Date"},
    {key: "in_stock", label: "In Stock"},
    {key: "quantity", label: "Quantity"},
];

export const SortFilters = [
    {key: "balance", label: "Balance"},
    {key: "invoiced_amount", label: "Invoiced Amount"},
];
