import {useEffect, useState} from "react";
import {makeNetworkCall} from "../utils/fetchData";

export const useFetchData = (
    page,
    rowsPerPage,
    urlEndpoint,
    active_orders,
    product_type
) => {
    const [data, setData] = useState([]);
    const [totals, setTotals] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortOption, setSortOption] = useState("id");
    const [sortOrder, setSortOrder] = useState("desc");

    useEffect(() => {
        const payload = {
            offset: page * rowsPerPage,
            per_page: rowsPerPage,
            sort_order: `${sortOption}:${sortOrder}`,
            query: searchTerm,
            product_type: product_type,
            ...(active_orders != null && {active_orders}),
            ...(product_type != null && {product_type}),
        };
        makeNetworkCall(
            urlEndpoint,
            payload,
            setData,
            setTotals,
            setIsLoading,
            {}
        );
    }, [page, rowsPerPage]);

    return {
        data,
        totals,
        isLoading,
        setTotals,
        setData,
        setIsLoading,
        sortOrder,
        sortOption,
        searchTerm,
        setSearchTerm,
        setSortOption,
        setSortOrder,
    };
};
export const useLoadData = (formFields, urlEndpoint) => {
    const [data, setData] = useState([]);
    const [totals, setTotals] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortOption, setSortOption] = useState("id");
    const [sortOrder, setSortOrder] = useState("desc");

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const updatedFormFields = {
                    ...formFields,
                    sort_order: `${sortOption}:${sortOrder}`,
                    query: searchTerm,
                };

        await makeNetworkCall(
          urlEndpoint,
          updatedFormFields,
          setData,
          setTotals,
          setIsLoading,
          {}
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [
    formFields.per_page,
    formFields.offset,
    formFields.user_type,
    searchTerm,
    sortOption,
    sortOrder,
    urlEndpoint,
  ]);

    return {
        data,
        totals,
        isLoading,
        setTotals,
        setData,
        setIsLoading,
        sortOrder,
        sortOption,
        searchTerm,
        setSearchTerm,
        setSortOption,
        setSortOrder,
    };
};

export const usePrepareData = (formFields, urlEndpoint) => {
    const [data, setData] = useState([]);
    const [totals, setTotals] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortOption, setSortOption] = useState("id");
    const [sortOrder, setSortOrder] = useState("desc");

    useEffect(() => {
        formFields.sort_order = `${sortOption}:${sortOrder}`;
        formFields.query = searchTerm;
    }, [
        formFields,
        searchTerm,
        sortOption,
        sortOrder,
    ]);

    return {
        data,
        totals,
        isLoading,
        setTotals,
        setData,
        setIsLoading,
        sortOrder,
        sortOption,
        searchTerm,
        setSearchTerm,
        setSortOption,
        setSortOrder,
    };
};
