import PropTypes from "prop-types";
import {Avatar, Card, CardContent, Stack, SvgIcon, Typography,} from "@mui/material";
import {PriceCheck} from "@mui/icons-material";

export const OverviewTotalOrdersPaid = (props) => {
    const {value, sx} = props;

    return (
        <Card sx={sx}>
            <CardContent>
                <Stack
                    alignItems="flex-start"
                    direction="row"
                    justifyContent="space-between"
                    spacing={3}
                >
                    <Stack spacing={1}>
                        <Typography color="text.secondary" variant="overline">
                            Pending payment orders
                        </Typography>
                        <Typography variant="h5">{value}</Typography>
                    </Stack>
                    <Avatar
                        sx={{
                            backgroundColor: "success.main",
                            height: 56,
                            width: 56,
                        }}
                    >
                        <SvgIcon>
                            <PriceCheck/>
                        </SvgIcon>
                    </Avatar>
                </Stack>
            </CardContent>
        </Card>
    );
};

OverviewTotalOrdersPaid.propTypes = {
    value: PropTypes.string,
    sx: PropTypes.object,
};
