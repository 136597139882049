import PropTypes from "prop-types";
import {
    Box,
    Card,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import ProgressBars from "../../../utils/loading";
import {useNavigate} from "react-router-dom";

export const IssuedItemTable = (props) => {
    const {
        count = 0,
        items = [],
        onPageChange = () => {
        },
        onRowsPerPageChange,
        page = 0,
        rowsPerPage = 0,
        isLoading = false,
        componentRef,
    } = props;

    const navigate = useNavigate();
    const handleNavigate = (id) => {
        navigate(`/student-allocation/${id}`);
    };
    return (
        <Card>
            <Box ref={componentRef} sx={{minWidth: 800}}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Item</TableCell>
                                <TableCell>
                                    Total
                                    <br/>
                                    Issued
                                </TableCell>
                                <TableCell>Student Name</TableCell>
                                <TableCell>Student ADM</TableCell>
                                <TableCell>Student Class</TableCell>
                            </TableRow>
                        </TableHead>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={7}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            minHeight: 200,
                                        }}
                                    >
                                        <ProgressBars/>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : items.length === 0 ? ( // Check if items array is empty
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        <Typography variant="subtitle1">
                                            Select A Product, No data available for this product
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {items.map((student, index) => {
                                    const rowNum = page * rowsPerPage + index + 1; // Calculate the row number

                                    return (
                                        <TableRow
                                            hover
                                            key={student.student.student_number}
                                            onClick={() =>
                                                handleNavigate(student.student.student_number)
                                            }
                                        >
                                            <TableCell>{rowNum}</TableCell>
                                            <TableCell>{student.product_name}</TableCell>
                                            <TableCell>{student.total_issued}</TableCell>
                                            <TableCell>{student.student.student_name}</TableCell>
                                            <TableCell>{student.student.student_number}</TableCell>
                                            <TableCell>{`${student.student?.form} ${student.student?.stream}`}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Box>

            <TablePagination
                component="div"
                count={count}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[25, 50, 100]}
            />
        </Card>
    );
};

IssuedItemTable.propTypes = {
    count: PropTypes.number,
    items: PropTypes.array,
    onDeselectAll: PropTypes.func,
    onDeselectOne: PropTypes.func,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    onSelectAll: PropTypes.func,
    onSelectOne: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    selected: PropTypes.array,
    isLoading: PropTypes.bool,
};
