import React, {useState} from "react";
import PropTypes from "prop-types";
import {
    Box,
    Card,
    IconButton,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ProgressBars from "../../utils/loading";
import {useNavigate} from "react-router-dom";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import {DateTimeFormate} from "../../utils/dateTime-fomater";
import {SeverityPill} from "../../components/severity-pill";
import AddProduct from "./add-product";
import DeletePopup from "../../components/deletePopup";
import {DELETE_PRODUCT_ENDPOINT} from "../../utils/constants";

export const ProductTable = (props) => {
    const {
        count = 0,
        items = [],
        onPageChange = () => {
        },
        onRowsPerPageChange,
        page = 0,
        rowsPerPage = 0,
        isLoading = false,
        componentRef,
    } = props;

    const navigate = useNavigate();

    // State to handle the dropdown menu
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [dialogDeleteOpen, setDialogDeleteOpen] = React.useState(false);
    const [selectedEntity, setSelectedEntity] = React.useState({
        id: null,
        name: "",
    });

    const handleTableRowClick = (product) => {
        const {id, item_name} = product.product;
        navigate(`/inventory/${id}/${item_name.replace("/", "|")}`);
    };

    const handleMenuOpen = (event, product) => {
        setAnchorEl(event.currentTarget);
        setSelectedProduct(product);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedProduct(null);
    };

    const handleEditClick = () => {
        setOpenEdit({open: true, data: selectedProduct});
        handleMenuClose();
    };
    const handleDeleteClick = (order) => {
        setDialogDeleteOpen(true);
        setSelectedEntity({
            id: selectedProduct.product.id,
            type: `this product, ${selectedProduct.product.item_name}`,
        });
        setAnchorEl(null);
    };
    const [openEdit, setOpenEdit] = React.useState({
        open: false,
        data: null,
    });

    return (
        <Card>
            <Box ref={componentRef} sx={{minWidth: 800}}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Item Name</TableCell>
                                <TableCell>Total Delivered</TableCell>
                                <TableCell>In Stock</TableCell>
                                <TableCell>Active Orders</TableCell>
                                <TableCell>Starting Stock</TableCell>
                                <TableCell>Added By</TableCell>
                                <TableCell>Date Added</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={9}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            minHeight: 200,
                                        }}
                                    >
                                        <ProgressBars/>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : items.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={9}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            minHeight: 200,
                                        }}
                                    >
                                        <CustomNoRowsOverlay/>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableBody>
                                {items.map((product, index) => {
                                    const rowNum = page * rowsPerPage + index + 1;

                                    return (
                                        <React.Fragment key={product.product.id}>
                                            <TableRow hover>
                                                <TableCell onClick={() => handleTableRowClick(product)}>
                                                    {rowNum}
                                                </TableCell>
                                                <TableCell onClick={() => handleTableRowClick(product)}>
                                                    <ListItemText
                                                        primary={`${product.product.item_name}`}
                                                        primaryTypographyProps={{variant: "subtitle1"}}
                                                        secondary={product.product.category}
                                                        secondaryTypographyProps={{variant: "caption"}}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        textTransform: "lowercase",
                                                    }}
                                                >
                                                    {`${product.total_quantity_delivered} out of ${product.total_quantity_ordered}
                        ${product.product.unit_of_measurement} `}
                                                </TableCell>
                                                <TableCell onClick={() => handleTableRowClick(product)}>
                                                    {
                                                        <SeverityPill
                                                            color="warning">{`${product.total_in_stock} ${product.product.unit_of_measurement}`}</SeverityPill>
                                                    }
                                                </TableCell>
                                                <TableCell onClick={() => handleTableRowClick(product)}>
                                                    {product.active_orders}
                                                </TableCell>
                                                <TableCell onClick={() => handleTableRowClick(product)}>
                                                    {product.product.initial_stock}
                                                </TableCell>
                                                <TableCell onClick={() => handleTableRowClick(product)}>
                                                    {product.added.full_name}
                                                </TableCell>
                                                <TableCell onClick={() => handleTableRowClick(product)}>
                                                    {DateTimeFormate(product.product.date_added)}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        aria-label="more"
                                                        aria-controls="long-menu"
                                                        aria-haspopup="true"
                                                        onClick={(event) => handleMenuOpen(event, product)}
                                                    >
                                                        <MoreVertIcon/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Box>

            <TablePagination
                component="div"
                count={count}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[25, 50, 100]}
            />

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleEditClick}>Edit</MenuItem>
                <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
            </Menu>

            {openEdit.open && (
                <div style={{display: "none"}}>
                    <AddProduct mode="edit" data={openEdit.data}/>
                </div>
            )}
            {dialogDeleteOpen && (
                <DeletePopup
                    dialogOpen={dialogDeleteOpen}
                    setDialogOpen={setDialogDeleteOpen}
                    selectedEntity={selectedEntity}
                    urlLink={DELETE_PRODUCT_ENDPOINT}
                />
            )}
        </Card>
    );
};

ProductTable.propTypes = {
    count: PropTypes.number,
    items: PropTypes.array,
    onDeselectAll: PropTypes.func,
    onDeselectOne: PropTypes.func,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    onSelectAll: PropTypes.func,
    onSelectOne: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    selected: PropTypes.array,
    isLoading: PropTypes.bool,
};
