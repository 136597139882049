import { useMemo } from "react";
import { useSelection } from "../../hooks/use-selection";
import { PurchaseOrdersSearch } from "../../sections/purchaseOrders/purchaseOrders-search";
import { OrdersTable } from "../../sections/purchaseOrders/purchaseOrders-table";
import { SCHOOL_ORDERS_ENDPOINT } from "../../utils/constants";
import BasePageConfigs from "../page-configs";
import BasePage from "../page-blueprint";
import { useLoadData } from "../../services/featching-data-service";

const useOrderIds = (orders) => {
  return useMemo(() => {
    return orders.map((order) => order.id);
  }, [orders]);
};

const OrderPage = ({ type }) => {
  const {
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    componentRef,
  } = BasePageConfigs();

  const payload = {
    offset: page * rowsPerPage,
    per_page: rowsPerPage,
    product_type: type,
  };

  const {
    data: orders,
    totals,
    isLoading,
    setTotals,
    setData,
    setIsLoading,
  } = useLoadData(payload, SCHOOL_ORDERS_ENDPOINT);
  const orderIds = useOrderIds(orders);
  const ordersSelection = useSelection(orderIds);

  const pageTitle =
    (type === "lso" && "LSO Orders") ||
    (type === "lpo" && "Purchase Orders") ||
    (type === "parents" && "Parent Orders");

  return BasePage(
    pageTitle,
    null,
    <PurchaseOrdersSearch
      setIsLoading={setIsLoading}
      setData={setData}
      setTotals={setTotals}
      payload={payload}
      baseEndpoint={SCHOOL_ORDERS_ENDPOINT}
    />,
    <OrdersTable
      type={type}
      totals={totals}
      items={orders}
      onDeselectAll={ordersSelection.handleDeselectAll}
      onDeselectOne={ordersSelection.handleDeselectOne}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      onSelectAll={ordersSelection.handleSelectAll}
      onSelectOne={ordersSelection.handleSelectOne}
      page={page}
      rowsPerPage={rowsPerPage}
      selected={ordersSelection.selected}
      isLoading={isLoading}
      componentRef={componentRef}
    />,
    componentRef
  );
};

export default OrderPage;
