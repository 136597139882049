import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  SvgIcon,
} from "@mui/material";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import * as endpoints from "../../utils/constants";
import { CreateItem } from "../../utils/fetchData";
import { toast } from "react-toastify";
import Student from "../../components/student-search";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddSupplier({ type, supplierData, modeType }) {
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState(() => {
    const initialData = {
      company_name: "",
      full_name: "",
      user_type: modeType,
      phone_number: "",
      physical_address: "",
      company_registration_number: "N/A",
      notes: "",
      student_number: "",
    };

    return initialData;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFormData({
      company_name: "",
      full_name: "",
      user_type: modeType,
      phone_number: "",
      physical_address: "",
      company_registration_number: "N/A",
      notes: "",
      student_number: "",
    });
    setOpen(false);
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    // Define the required fields for each modeType
    const requiredFields = {
      SUPPLIER: [
        "company_name",
        "full_name",
        "phone_number",
        "physical_address",
        "company_registration_number",
        "user_type",
      ],
      PARENT: ["full_name", "phone_number", "student_number", "user_type"],
      "SERVICE-PROVIDER": [
        "full_name",
        "phone_number",
        "company_name",
        "company_registration_number",
        "user_type",
        "physical_address",
      ],
    };

    // Get the required fields for the current modeType
    const currentRequiredFields = requiredFields[modeType] || [];

    // Check if any required field in formData is empty
    for (const field of currentRequiredFields) {
      if (!formData[field]) {
        toast.error(
          `Please fill in the required field: ${field.replace("_", " ")}.`
        );
        return;
      }
    }

    // If all required fields are filled, call CreateItem function
    CreateItem(formData, endpoints.NEW_SUPPLIER_ENDPOINT);
  };

  React.useEffect(() => {
    if (type === "edit-supplier" && supplierData) {
      setFormData({
        company_name: supplierData.supplier.company_name || "",
        full_name: supplierData.supplier.full_name || "",
        user_type: supplierData?.supplier.user_type || modeType,
        id: supplierData.supplier.id || "",
        phone_number: supplierData.supplier.phone_number || "",
        physical_address: supplierData.supplier.physical_address || "",
        company_registration_number:
          supplierData.supplier.company_registration_number || "",
        notes: supplierData.supplier.notes || "",
        student_number: supplierData?.supplier?.student_number || "",
      });
      handleClickOpen();
    }
  }, [type, supplierData]);

  const effectiveModeType =
    type === "edit-supplier" && supplierData
      ? supplierData.supplier.user_type // Use modeType from supplierData when editing
      : modeType; // Use passed modeType otherwise

  const propTitle = React.useMemo(() => {
    console.log("effectiveModeType:", effectiveModeType);
    switch (effectiveModeType) {
      case "SUPPLIER":
        return "School Suppliers";
      case "PARENT":
        return "School Supplier Parents";
      case "SERVICE-PROVIDER":
        return "School Service Providers";
      default:
        return "School Entities";
    }
  }, [effectiveModeType]);

  console.log("propTitle:", propTitle);

  return (
    <React.Fragment>
      {type === "edit-supplier" ? (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleClickOpen}
        >
          Edit
        </Button>
      ) : (
        <Button
          startIcon={
            <SvgIcon fontSize="small">
              <PlusIcon />
            </SvgIcon>
          }
          variant="contained"
          onClick={handleClickOpen}
        >
          {`Add ${propTitle}`}
        </Button>
      )}

      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {type === "edit-supplier"
                ? `Edit the ${propTitle}`
                : `Add ${propTitle}`}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <Container sx={{ marginTop: "1rem" }}>
            <Card sx={{ p: 2, display: "flex", flexWrap: "wrap" }}>
              <Box sx={{ width: "100%" }}>
                <CardHeader
                  subheader={`Fill in all the inputs to be able to ${
                    type === "edit-supplier"
                      ? `edit ${propTitle}`
                      : `create a ${propTitle}`
                  }`}
                  title={
                    type === "edit-supplier"
                      ? `Edit ${propTitle}`
                      : `Add a ${propTitle}`
                  }
                />
                <Divider />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {modeType === "PARENT" ? (
                    <>
                      {[
                        {
                          label: "Parent Name",
                          placeholder: "",
                          field: "full_name",
                        },
                        {
                          label: "Phone Number",
                          placeholder: "",
                          field: "phone_number",
                        },
                      ].map((inputField, index) => (
                        <React.Fragment key={index}>
                          <ListItem
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "start",
                            }}
                          >
                            <p>
                              {inputField.label}
                              <span style={{ color: "red", marginLeft: "5px" }}>
                                *{" "}
                              </span>
                            </p>
                            <TextField
                              fullWidth
                              variant="outlined"
                              placeholder={inputField.placeholder}
                              value={formData[inputField.field]}
                              onChange={(e) =>
                                handleInputChange(
                                  inputField.field,
                                  e.target.value
                                )
                              }
                            />
                          </ListItem>
                        </React.Fragment>
                      ))}
                    </>
                  ) : (
                    <>
                      {[
                        {
                          label: "Company name",
                          placeholder: "",
                          field: "company_name",
                        },
                        {
                          label: "Supplier Name",
                          placeholder: "",
                          field: "full_name",
                        },
                        {
                          label: "Phone Number",
                          placeholder: "",
                          field: "phone_number",
                        },
                      ].map((inputField, index) => (
                        <React.Fragment key={index}>
                          <ListItem
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "start",
                            }}
                          >
                            <p>
                              {inputField.label}
                              <span style={{ color: "red", marginLeft: "5px" }}>
                                *{" "}
                              </span>
                            </p>
                            <TextField
                              fullWidth
                              variant="outlined"
                              placeholder={inputField.placeholder}
                              value={formData[inputField.field]}
                              onChange={(e) =>
                                handleInputChange(
                                  inputField.field,
                                  e.target.value
                                )
                              }
                            />
                          </ListItem>
                        </React.Fragment>
                      ))}
                    </>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {modeType === "PARENT" ? (
                    <>
                      <Student
                        selectedStudent={formData.student_number}
                        setSelectedStudent={(value) =>
                          handleInputChange("student_number", value)
                        }
                      />
                    </>
                  ) : (
                    <>
                      {[
                        {
                          label: " Company Location",
                          placeholder: "",
                          field: "physical_address",
                        },
                        {
                          label: "Company Registration Number",
                          placeholder: "",
                          field: "company_registration_number",
                        },
                      ].map((inputField, index) => (
                        <React.Fragment key={index}>
                          <ListItem
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "start",
                            }}
                          >
                            <p>
                              {inputField.label}
                              <span style={{ color: "red", marginLeft: "5px" }}>
                                *{" "}
                              </span>
                            </p>
                            <TextField
                              fullWidth
                              variant="outlined"
                              placeholder={inputField.placeholder}
                              value={formData[inputField.field]}
                              onChange={(e) =>
                                handleInputChange(
                                  inputField.field,
                                  e.target.value
                                )
                              }
                            />
                          </ListItem>
                        </React.Fragment>
                      ))}
                    </>
                  )}
                </Grid>
              </Grid>
              <ListItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "start",
                }}
              >
                <Button
                  sx={{ width: "100%", marginRight: "5px" }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </ListItem>
            </Card>
          </Container>
        </List>
      </Dialog>
    </React.Fragment>
  );
}
