import {Avatar, Card, CardContent, Stack, SvgIcon, Typography,} from "@mui/material";
import {UsersIcon} from "@heroicons/react/24/solid";

export const OverviewSuppliers = (props) => {
    const {value, sx} = props;

    return (
        <Card sx={sx}>
            <CardContent>
                <Stack
                    alignItems="flex-start"
                    direction="row"
                    justifyContent="space-between"
                    spacing={3}
                >
                    <Stack spacing={1}>
                        <Typography color="text.secondary" gutterBottom variant="overline">
                            School Suppliers
                        </Typography>
                        <Typography variant="h5">{value}</Typography>
                    </Stack>
                    <Avatar
                        sx={{
                            backgroundColor: "warning.main",
                            height: 56,
                            width: 56,
                        }}
                    >
                        <SvgIcon>
                            <UsersIcon/>
                        </SvgIcon>
                    </Avatar>
                </Stack>
                {/* <Box sx={{ mt: 3 }}>
          <LinearProgress value={value} variant="determinate" />
        </Box> */}
            </CardContent>
        </Card>
    );
};
