// AllocationTable component
import PropTypes from "prop-types";
import {
    Box,
    Card,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import ProgressBars from "../../utils/loading";
import {SeverityPill} from "../../components/severity-pill";
import {useNavigate} from "react-router-dom";

export const StudentListTable = (props) => {
    const {
        count = 0,
        items = [],
        onPageChange = () => {
        },
        onRowsPerPageChange,
        page = 0,
        rowsPerPage = 0,
        isLoading = false,
        componentRef,
    } = props;

    const navigate = useNavigate();

    const handleNavigate = (student) => {
        const {
            student_number,
        } = student;

        navigate(`/student-allocation/${student_number}/`);
    };
    return (
        <Card>
            <Box ref={componentRef} sx={{minWidth: 800}}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell> </TableCell>
                                <TableCell>Adm No</TableCell>
                                <TableCell>Name </TableCell>
                                <TableCell>Class</TableCell>
                                <TableCell>Stream</TableCell>
                                <TableCell>Contact No.</TableCell>
                                <TableCell>In School </TableCell>
                            </TableRow>
                        </TableHead>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={7}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            minHeight: 200,
                                        }}
                                    >
                                        <ProgressBars/>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableBody>
                                {items.map((student, index) => {
                                    const rowNum = page * rowsPerPage + index + 1;
                                    return (
                                        <TableRow
                                            onClick={() => handleNavigate(student)}
                                            key={student.id}
                                        >
                                            <TableCell>{rowNum}</TableCell>
                                            <TableCell>{student.student_number}</TableCell>
                                            <TableCell>{student.student_name}</TableCell>
                                            <TableCell>{student.form}</TableCell>
                                            <TableCell>{student.stream}</TableCell>
                                            <TableCell>{student.contact_number}</TableCell>
                                            <TableCell>
                                                <SeverityPill
                                                    color={
                                                        student.in_school === true ? "success" : "error"
                                                    }
                                                >
                                                    {student.in_school.toString()}
                                                </SeverityPill>{" "}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Box>

            <TablePagination
                component="div"
                count={count}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[25, 50, 100]}
            />
        </Card>
    );
};

StudentListTable.propTypes = {
    count: PropTypes.number,
    items: PropTypes.array,
    onDeselectAll: PropTypes.func,
    onDeselectOne: PropTypes.func,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    onSelectAll: PropTypes.func,
    onSelectOne: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    selected: PropTypes.array,
    isLoading: PropTypes.bool,
};
