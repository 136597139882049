import {
  TextField,
  InputAdornment,
  Button,
  Radio,
  FormControlLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SEARCH_STUDENT_ENDPOINT } from "../utils/constants";
import { makeNetworkCall } from "../utils/fetchData";

const Student = ({ selectedStudent, setSelectedStudent }) => {
  const [error, setError] = useState(false);
  const [disabledInput, setDisabledInput] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [student, setStudent] = useState("");
  const [studentData, setStudentData] = useState([]);
  const [notFoundMessage, setNotFoundMessage] = useState(false);

  const getStudent = async () => {
    setError(false); // Reset error state
    setNotFoundMessage(false); // Reset not found message

    makeNetworkCall(
      SEARCH_STUDENT_ENDPOINT,
      {
        student_number: student,
      },
      setStudentData,
      null,
      null,
      {},
      "POST"
    );
  };

  const handleStudentChange = (e) => {
    const inputStudent = e.target.value.replace(/\D/g, "");
    setStudent(inputStudent);
  };

  const handleEditClick = () => {
    setDisabledInput(false);
  };

  const handleStudentClick = async () => {
    if (student.length === 0) {
      setError(true);
      return;
    }

    try {
      await getStudent();
      setDisabledInput(true);
      setDisabledBtn(true);
    } catch (error) {
      toast.log(error);
    }
  };

  const handleRadioChange = (event) => {
    setSelectedStudent(event.target.value);
  };

  useEffect(() => {
    setDisabledBtn(student.length === 0);
    if (studentData.length === 0) {
      setNotFoundMessage(true);
    } else {
      setNotFoundMessage(false);
    }
  }, [student, studentData]);

  return (
    <div className="student_container">
      <div className="container_header">
        <p>
          Search Student
          <span style={{ color: "red", marginLeft: "5px" }}>* </span>
        </p>
      </div>
      <TextField
        className="form_input"
        style={{ minWidth: "300px", width: "100%" }}
        id="outlined-basic"
        label="Enter admission no. **"
        variant="outlined"
        value={student}
        onChange={handleStudentChange}
        disabled={disabledInput}
        helperText={error && "Student with that admission was not found"}
        error={error}
        InputProps={{
          endAdornment: disabledInput && (
            <InputAdornment onClick={handleEditClick} position="end">
              <div className="phone_container_p">
                <p>Edit</p>
              </div>
            </InputAdornment>
          ),
        }}
      />

      {!disabledInput && (
        <Button
          variant="contained"
          disabled={disabledBtn}
          onClick={handleStudentClick}
        >
          Continue
        </Button>
      )}

      {studentData.length > 0 && (
        <div>
          <h3>Select a student</h3>
          {studentData.map((student) => (
            <FormControlLabel
              key={student.student_number}
              control={
                <Radio
                  checked={selectedStudent === student.student_number}
                  onChange={handleRadioChange}
                  value={student.student_number}
                />
              }
              label={`${student.student_name} (Form ${student.form} ${student.stream})`}
            />
          ))}
        </div>
      )}

      {notFoundMessage && disabledInput && (
        <div style={{ color: "red", marginTop: "10px" }}>
          Student with this admission number not found.
        </div>
      )}
    </div>
  );
};

export default Student;
