import { useMemo } from "react";
import { useSelection } from "../../hooks/use-selection";
import { SuppliersTable } from "../../sections/suppliers/suppliers-table";
import { SuppliersSearch } from "../../sections/suppliers/suppliers-search";
import AddSupplier from "../../sections/suppliers/add-suppliers";
import { SCHOOL_SUPPLIERS_ENDPOINT } from "../../utils/constants";
import BasePage from "../page-blueprint";
import BasePageConfigs from "../page-configs";
import { useLoadData } from "../../services/featching-data-service";

const useSupplierIds = (suppliers) => {
  return useMemo(() => {
    return suppliers.map((supplier) => supplier.id);
  }, [suppliers]);
};

const SuppliersPage = ({ type }) => {
  const {
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    componentRef,
  } = BasePageConfigs();
  const payload = {
    offset: page * rowsPerPage,
    per_page: rowsPerPage,
    user_type: type,
  };
  const {
    data: suppliers,
    totals,
    isLoading,
    setTotals,
    setIsLoading,
    setData,
  } = useLoadData(payload, SCHOOL_SUPPLIERS_ENDPOINT);
  const supplierIds = useSupplierIds(suppliers);
  const suppliersSelection = useSelection(supplierIds);

  const propTitle =
    (type === "SUPPLIER" && "School Suppliers") ||
    (type === "PARENT" && "School Supplier Parents") ||
    (type === "SERVICE-PROVIDER" && "School Service Providers");
  return BasePage(
    propTitle,
    <AddSupplier
      modeType={type}
      setData={setData}
      setIsLoading={setIsLoading}
      page={page}
      rowsPerPage={rowsPerPage}
    />,
    <SuppliersSearch
      data={suppliers}
      setData={setData}
      setTotals={setTotals}
      setIsLoading={setIsLoading}
      payload={payload}
      baseEndpoint={SCHOOL_SUPPLIERS_ENDPOINT}
    />,
    <SuppliersTable
      type={type}
      totals={totals}
      items={suppliers}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      page={page}
      rowsPerPage={rowsPerPage}
      selected={suppliersSelection.selected}
      isLoading={isLoading}
      componentRef={componentRef}
    />,
    componentRef
  );
};

export default SuppliersPage;
