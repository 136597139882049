import {Box} from "@mui/material";
import {useEffect, useState} from "react";
import {SCHOOL_PAYMENTS_ENDPOINT,} from "../../utils/constants";
import BetweenDatePicker from "../../utils/betweenDates-picker";
import {BaseSearchBox} from "../search-box";
import {PaymentSortFilters} from "../../utils/constants-types";

export const PaymentSearch = (
    {
        data,
        setTotals,
        setData,
        setIsLoading,
        payload,
    }
) => {
    const [endDate, setEndDate] = useState("");
    const [startDate, setStartDate] = useState("");

    useEffect(() => {
        if (startDate && endDate) {
            payload.start_date = startDate;
            payload.end_date = endDate;
        }
    }, [payload, endDate, startDate]);

    const dateFilters = (
        <Box sx={{width: "100%", display: "flex", marginLeft: "25px"}}>
            <BetweenDatePicker
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                label="Filters between dates"
            />
        </Box>
    );

    return BaseSearchBox({
        placeholder: "Enter cheque number or inventory name...",
        endpoint: SCHOOL_PAYMENTS_ENDPOINT,
        data: data,
        setData: setData,
        setTotals: setTotals,
        setIsLoading: setIsLoading,
        payload: payload,
        otherFilters: dateFilters,
        sortFilters: PaymentSortFilters,
    });
};
