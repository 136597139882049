export const DEFAULT_PER_PAGE = 25;
let BASE_URL = "";
let API_BASE_URL = "";
if (process.env.NODE_ENV === "development") {
  // BASE_URL = "http://localhost/backends/mzazipay/";
  // API_BASE_URL = "http://127.0.0.1:8000/";
  BASE_URL = "https://mzazilink.com/e-wallet/";
  API_BASE_URL = "http://mzazilink.com:81/";
} else {
  BASE_URL = "https://mzazilink.com/e-wallet/";
  API_BASE_URL = "http://mzazilink.com:81/";
}

const V1_URL = "api/v1";
const V2_URL = "api/v2";
const PROCUREMENT_PATH = `${V1_URL}/school/procurement`;
const PROCUREMENT_BASE_PATH = `${V1_URL}/procurement`;

const constructUrl = (version, endpoint) => {
  return `${API_BASE_URL}${version}/${endpoint}`;
};

export const GET_ENDPOINT = `${BASE_URL}${PROCUREMENT_BASE_PATH}/get-endpoint/`;
export const POST_FORM_ENDPOINT = `${BASE_URL}${PROCUREMENT_BASE_PATH}/post-form-endpoint/`;
export const POST_RAW_ENDPOINT = `${BASE_URL}${PROCUREMENT_BASE_PATH}/post-raw-endpoint/`;

export const LOGIN_ENDPOINT = constructUrl(V2_URL, "school/user/login/");
export const PROCUREMENT_LOGIN_ENDPOINT = `${BASE_URL}${V1_URL}/school/user/login/`;
export const LOGOUT_ENDPOINT = constructUrl(V2_URL, "school/user/logout/");
export const PROCUREMENT_LOGOUT_ENDPOINT = `${BASE_URL}${V1_URL}/school/user/logout/`;

export const STREAMS_ENDPOINT = constructUrl(V2_URL, "school/streams/");
export const STUDENTS_PER_STREAM_ENDPOINT = constructUrl(
  V2_URL,
  "school/students/per-stream/"
);
export const SEARCH_STUDENT_ENDPOINT = constructUrl(
  V2_URL,
  "school/search-student/"
);

export const DASHBOARD_ENDPOINT = constructUrl(PROCUREMENT_PATH, "dashboard/");

export const PRODUCTS_ENDPOINT = constructUrl(PROCUREMENT_PATH, "products/");
export const MINIFIED_PRODUCTS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "minified-products/"
);
export const PRODUCT_ORDERS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "product-orders/"
);
export const PRODUCT_PAYMENTS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "product-payments/"
);
export const PRODUCT_DELIVERIES_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "product-deliveries/"
);
export const PRODUCT_STAFF_ISSUANCE_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "product-allocations/"
);

export const ISSUE_STUDENT_ITEM_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "issue-student-item/"
);
export const RETURN_STUDENT_ITEM_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "receive-student-item/"
);
export const STUDENT_ITEMS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "student-items/"
);
export const SCHOOL_STUDENT_ITEMS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "school-student-items/"
);
export const PRODUCT_STUDENT_ISSUANCE_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "student-items-per-product/"
);
export const STUDENT_ITEMS_RETURNS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "stream-students-item-received/"
);
export const STUDENT_ITEMS_ISSUANCE_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "stream-students-item-issued/"
);

export const NEW_DELIVERY_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "new-delivery/"
);
export const SCHOOL_DELIVERIES_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "school-deliveries/"
);

export const SUPPLIER_DELIVERIES_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "supplier-deliveries/"
);
export const ORDER_DELIVERIES_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "order-deliveries/"
);
export const DELIVERIES_BETWEEN_DATES_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "deliveries-between-dates/"
);

export const NEW_SUPPLIER_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "new-supplier/"
);
export const SCHOOL_SUPPLIERS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "suppliers/"
);
export const LEAN_SUPPLIERS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "minimal-data-suppliers/"
);

export const NEW_STAFF_ISSUANCE_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "new-allocation/"
);

export const NEW_ORDER_ENDPOINT = constructUrl(PROCUREMENT_PATH, "new-order/");
export const SCHOOL_ORDERS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "school-orders/"
);
export const GROUPED_SCHOOL_ORDERS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "group-orders/"
);
export const SCHOOL_GROUPED_ORDERS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "group-orders/"
);

export const NEW_INVENTORY_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "new-product/"
);

export const NEW_PAYMENT_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "new-payment/"
);
export const SCHOOL_PAYMENTS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "school-payments/"
);

export const NEW_EXPENSES_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "new-expense/"
);
export const EXPENSES_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "school-expense/"
);

export const NEW_ALLOCATIONS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "new-allocation/"
);
export const ALLOCATIONS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "allocations/"
);

export const PRODUCT_ALLOCATIONS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "product-allocations/"
);
export const STAFF_ALLOCATIONS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "staff-allocations/"
);

export const NEW_STAFF_ENDPOINT = constructUrl(V2_URL, "school/staff/new/");
export const SEARCH_STAFF_ENDPOINT = constructUrl(
  V2_URL,
  "school/staff/search/"
);
export const SCHOOL_STAFF_ENDPOINT = constructUrl(V2_URL, "school/staff/all/");
export const SINGLE_STAFF_ENDPOINT = constructUrl(
  V2_URL,
  "school/staff/single/"
);

export const SEARCH_PAYMENTS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "search-payments/"
);
export const SEARCH_INVENTORY_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "search-products/"
);
export const SEARCH_EXPENSES_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "search-expenses/"
);

export const SYSTEM_SETTINGS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "settings/"
);
export const NEW_SETTINGS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "new-settings/"
);
export const GET_SETTINGS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "get-settings/"
);
export const UPDATE_SETTINGS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "update-settings/"
);
export const SCHOOL_SETTINGS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "school-settings/"
);
export const SUPPLIER_ORDERS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "supplier-orders/"
);

export const SUPPLIER_PAYMENTS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "supplier-payments/"
);
export const ORDER_PAYMENTS_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "order-payments/"
);
export const SINGLE_ORDER_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "order-details/"
);

//Date filter Endpoint
export const ORDERS_BETWEEN_DATE_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "orders-between-dates/"
);
export const PAYMENTS_BETWEEN_DATES_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "payments-between-dates/"
);

export const DELETE_DELIVERY_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "delete-delivery/"
);
export const DELETE_ORDER_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "delete-order/"
);
export const DELETE_PRODUCT_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "delete-product/"
);

export const DELETE_PAYMENT_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "delete-payment/"
);

export const DELETE_ALLOCATION_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "delete-allocation/"
);

export const DELETE_STUDENT_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "delete-student-item/"
);

export const DELETE_SUPPLIER_ENDPOINT = constructUrl(
  PROCUREMENT_PATH,
  "delete-supplier/"
);

//page titles
export const INVENTORY_PAGE_TITLE = "School Inventory";

// image url
export const IMAGE_URL = "http://mzazilink.com:81/media/";
