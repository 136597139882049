import React from "react";
import PropTypes from "prop-types";
import {
    Box,
    Button,
    Card,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import ProgressBars from "../../utils/loading";
import {useNavigate} from "react-router-dom";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import {DateTimeFormate} from "../../utils/dateTime-fomater";
import AddLso from "./add-lsoInventory";

export const LsoTable = (props) => {
    const {
        count = 0,
        items = [],
        onPageChange = () => {
        },
        onRowsPerPageChange,
        page = 0,
        rowsPerPage = 0,
        isLoading = false,
        componentRef,
    } = props;

    const navigate = useNavigate();

    const handleTableRowClick = (product) => {
        const {id, item_name} = product.product;

        navigate(`/inventory/${id}/${item_name.replace("/", "|")}`);
    };

    const [openEdit, setOpenEdit] = React.useState({
        open: false,
        data: null,
    });

    const handleEditClick = (event, product) => {
        event.stopPropagation();
        setOpenEdit({open: true, data: product});
    };

    return (
        <Card>
            <Box ref={componentRef} sx={{minWidth: 800}}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Service</TableCell>
                                <TableCell>Completed</TableCell>
                                <TableCell>Active Orders</TableCell>
                                <TableCell>Added By</TableCell>
                                <TableCell>Date Added</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={9}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            minHeight: 200,
                                        }}
                                    >
                                        <ProgressBars/>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : items.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={9}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            minHeight: 200,
                                        }}
                                    >
                                        <CustomNoRowsOverlay/>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableBody>
                                {items.map((product, index) => {
                                    const rowNum = page * rowsPerPage + index + 1;

                                    return (
                                        <React.Fragment key={product.product.id}>
                                            <TableRow
                                                onClick={() => handleTableRowClick(product)}
                                                hover
                                            >
                                                <TableCell>{rowNum}</TableCell>
                                                <TableCell>
                                                    <ListItemText
                                                        primary={`${product.product.item_name}`}
                                                        primaryTypographyProps={{variant: "subtitle1"}}
                                                        secondary={product.product.category}
                                                        secondaryTypographyProps={{variant: "caption"}}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        textTransform: "lowercase",
                                                    }}
                                                >
                                                    {`${product.total_quantity_delivered} out of ${product.total_quantity_ordered}`}
                                                </TableCell>
                                                <TableCell>{product.active_orders}</TableCell>
                                                <TableCell>{product.added.full_name}</TableCell>
                                                <TableCell>
                                                    {DateTimeFormate(product.product.date_added)}
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        onClick={(event) => handleEditClick(event, product)}
                                                    >
                                                        Edit
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Box>

            <TablePagination
                component="div"
                count={count}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[25, 50, 100]}
            />
            {openEdit.open && (
                <div style={{display: "none"}}>
                    <AddLso mode="edit" data={openEdit.data}/>
                </div>
            )}
        </Card>
    );
};

LsoTable.propTypes = {
    count: PropTypes.number,
    items: PropTypes.array,
    onDeselectAll: PropTypes.func,
    onDeselectOne: PropTypes.func,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    onSelectAll: PropTypes.func,
    onSelectOne: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    selected: PropTypes.array,
    isLoading: PropTypes.bool,
};
