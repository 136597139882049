import {useCallback, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Alert, Box, Button, Stack, Tab, Tabs, TextField, Typography,} from "@mui/material";
import {useAuth} from "../../hooks/use-auth";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import {toast} from "react-toastify";

const LoginPage = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const [method, setMethod] = useState("phoneNumber");

    const formik = useFormik({
        initialValues: {
            phoneNumber: "",
            password: "",
            submit: null,
        },
        validationSchema: Yup.object({
            phoneNumber: Yup.string()
                .matches(/^\d{10}$/, "Must be a valid phone number")
                .required("Phone number is required"),
            password: Yup.string().max(255).required("Password is required"),
        }),
        onSubmit: async (values, helpers) => {
            try {
                // Use the signIn function from the AuthContext
                await auth.signIn(values.phoneNumber, values.password);

                // Redirect to the home page after successful login
                navigate("/");
            } catch (err) {
                toast.log(err);
                helpers.setStatus({success: false});
                helpers.setErrors({submit: err.message});
                helpers.setSubmitting(false);
            }
        },
    });

    const handleMethodChange = useCallback((event, value) => {
        setMethod(value);
    }, []);

    return (
        <>
            <Helmet>
                <title>Procurement | Login</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: "background.paper",
                    flex: "1 1 auto",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    minHeight: "100vh",
                }}
            >
                <Box
                    sx={{
                        maxWidth: 550,
                        px: 3,
                        py: "100px",
                        width: "100%",
                    }}
                >
                    <div>
                        <Stack spacing={1} sx={{mb: 3}}>
                            <Typography variant="h4">Login</Typography>
                        </Stack>
                        <Tabs onChange={handleMethodChange} sx={{mb: 3}} value={method}>
                            <Tab label="Phone Number" value="phoneNumber"/>
                        </Tabs>
                        {method === "phoneNumber" && (
                            <form noValidate onSubmit={formik.handleSubmit}>
                                <Stack spacing={3}>
                                    <TextField
                                        error={
                                            !!(
                                                formik.touched.phoneNumber && formik.errors.phoneNumber
                                            )
                                        }
                                        fullWidth
                                        helperText={
                                            formik.touched.phoneNumber && formik.errors.phoneNumber
                                        }
                                        label="Phone Number"
                                        name="phoneNumber"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        type="tel"
                                        value={formik.values.phoneNumber}
                                    />
                                    <TextField
                                        error={
                                            !!(formik.touched.password && formik.errors.password)
                                        }
                                        fullWidth
                                        helperText={
                                            formik.touched.password && formik.errors.password
                                        }
                                        label="Password"
                                        name="password"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        type="password"
                                        value={formik.values.password}
                                    />
                                </Stack>
                                {formik.errors.submit && (
                                    <Typography color="error" sx={{mt: 3}} variant="body2">
                                        {formik.errors.submit}
                                    </Typography>
                                )}
                                <Button
                                    fullWidth
                                    size="large"
                                    sx={{mt: 3}}
                                    type="submit"
                                    variant="contained"
                                >
                                    Continue
                                </Button>

                                <Alert color="primary" severity="info" sx={{mt: 3}}>
                                    <div>Login details are provided by your school admin</div>
                                </Alert>
                            </form>
                        )}
                    </div>
                </Box>
            </Box>
        </>
    );
};

export default LoginPage;
