import {Box, Button, Card, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, SvgIcon,} from "@mui/material";
import {useEffect, useState} from "react";
import ClearIcon from "@mui/icons-material/Clear";
import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import {makeNetworkCall} from "../utils/fetchData";
import {SEARCH_BOX_PADDING} from "../pages/configs";
import {usePrepareData} from "../services/featching-data-service";


export const BaseOrdersSearchBox = (
    {
        placeholder,
        endpoint,
        data,
        setData: updateData,
        setIsLoading,
        setTotals,
        payload,
        otherFilters = null,
    }
) => {
    const [refetch, setRefetch] = useState(false);
    const {
        sortOrder,
        sortOption,
        searchTerm,
        setSearchTerm,
        setSortOption,
        setSortOrder,
        setData,
    } = usePrepareData(payload, endpoint);

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearch = async () => {
        payload.query = searchTerm;
        payload.sort_order = `${sortOption}:${sortOrder}`;
        makeNetworkCall(
            endpoint,
            payload,
            updateData,
            setTotals,
            setIsLoading,
            {}
        );
    };

    const handleClearSearch = () => {
        setSearchTerm("");
        setData(data);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSearch();
        }
    };

    const handleSortOptionChange = (event) => {
        setSortOption(event.target.value);
        setRefetch(true);
    };

    const handleSortOrderChange = (event) => {
        setSortOrder(event.target.value);
        setRefetch(true);
    };

    useEffect(() => {
        if (refetch) {
            handleSearch();
            setRefetch(false);
        }
    }, [sortOption, sortOrder, refetch]);

    return (
        <Card>
            <Box
                sx={{
                    p: SEARCH_BOX_PADDING,
                    display: "flex",
                    alignItems: "center",
                    minWidth: "40%",
                }}
            >
                <OutlinedInput
                    defaultValue=""
                    fullWidth
                    onKeyDown={handleKeyDown}
                    value={searchTerm}
                    onChange={handleInputChange}
                    placeholder={placeholder}
                    startAdornment={
                        <InputAdornment position="start">
                            <SvgIcon color="action" fontSize="small">
                                <MagnifyingGlassIcon/>
                            </SvgIcon>
                        </InputAdornment>
                    }
                    endAdornment={
                        searchTerm && (
                            <InputAdornment position="end">
                                <IconButton onClick={handleClearSearch} edge="end">
                                    <ClearIcon/>
                                </IconButton>
                            </InputAdornment>
                        )
                    }
                    sx={{maxWidth: 400, minWidth: 400}}
                />
                <Button
                    onClick={handleSearch}
                    fullWidth
                    type="submit"
                    sx={{
                        height: "100%",
                        maxWidth: 100,
                        marginLeft: "20px",
                    }}
                    variant="contained"
                >
                    Search
                </Button>
                <Box sx={{maxWidth: "60%", display: "flex"}}>
                    {otherFilters != null && otherFilters}
                    <Select
                        value={sortOption}
                        onChange={handleSortOptionChange}
                        displayEmpty
                        fullWidth
                        sx={{minWidth: 35, marginLeft: "20px"}}
                    >
                        <MenuItem value="id" disabled>Delivery</MenuItem>
                        <MenuItem value="ALL">ALL</MenuItem>
                        <MenuItem value="PENDING">Pending Delivery</MenuItem>
                        <MenuItem value="PARTIALLY DELIVERED">Partially Delivered</MenuItem>
                        <MenuItem value="FULLY DELIVERED">Fully Delivered</MenuItem>
                    </Select>

                    <Select
                        value={sortOrder}
                        onChange={handleSortOrderChange}
                        fullWidth
                        sx={{minWidth: 35, marginLeft: "20px"}}
                    >
                        <MenuItem value="" disabled>Payment</MenuItem>
                        <MenuItem value="ALL">ALL</MenuItem>
                        <MenuItem value="PENDING">Pending Payment</MenuItem>
                        <MenuItem value="PARTIALLY PAID">Partially Paid</MenuItem>
                        <MenuItem value="FULLY PAID">Fully Paid</MenuItem>
                    </Select>
                </Box>
            </Box>
        </Card>
    );
};
