// itemTable component
import React from "react";
import PropTypes from "prop-types";
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import ProgressBars from "../../../utils/loading";
import CustomNoRowsOverlay from "../../../components/CustomNoRowsOverlay";
import {DateTimeFormate} from "../../../utils/dateTime-fomater";
import Paper from "@mui/material/Paper";
import {makeQuantityPositive} from "../../../utils/quantity-converter";

export const StudentReceivedItemsTable = (props) => {
    const {
        count = 0,
        items = [],
        onPageChange = () => {
        },
        onRowsPerPageChange,
        page = 0,
        rowsPerPage = 0,
        isLoading = false,
        componentRef,
    } = props;

    return (
        <Card>
            <Box ref={componentRef} sx={{minWidth: 800}}>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 700}}>
                        <TableHead>
                            <TableRow>
                                <TableCell> #</TableCell>
                                <TableCell>Name </TableCell>
                                <TableCell>Class </TableCell>
                                <TableCell>ADM </TableCell>
                                <TableCell>Product</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Issued By</TableCell>
                                <TableCell>Date Issued</TableCell>
                            </TableRow>
                        </TableHead>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={9}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            minHeight: 200,
                                        }}
                                    >
                                        <ProgressBars/>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : items.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={9}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            minHeight: 200,
                                        }}
                                    >
                                        <CustomNoRowsOverlay/>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableBody>
                                {items.map((item, index) => {
                                    const rowNum = page * rowsPerPage + index + 1;

                                    return (
                                        <TableRow hover key={item.id}>
                                            <TableCell>{rowNum}</TableCell>
                                            <TableCell>{item.student.student_name}</TableCell>
                                            <TableCell>{`${item.student.form} ${item.student.stream}`}</TableCell>
                                            <TableCell>{item.student.student_number}</TableCell>
                                            <TableCell>{item.product.item_name}</TableCell>
                                            <TableCell>{`${makeQuantityPositive(item.quantity)} ${
                                                item.product.unit_of_measurement
                                            }`}</TableCell>
                                            <TableCell>{item.allocated_by.full_name}</TableCell>
                                            <TableCell>{DateTimeFormate(item.date_added)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Box>

            <TablePagination
                component="div"
                count={count}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[25, 50, 100]}
            />
        </Card>
    );
};
StudentReceivedItemsTable.propTypes = {
    count: PropTypes.number,
    items: PropTypes.array,
    onDeselectAll: PropTypes.func,
    onDeselectOne: PropTypes.func,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    onSelectAll: PropTypes.func,
    onSelectOne: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    selected: PropTypes.array,
    isLoading: PropTypes.bool,
};
